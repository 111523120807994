import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "antd";
import moment from 'moment';

import AssignIndieGogoPerk from "../components/AssignIndieGogoPerk";

const IndeigogoTab = (props) => {
    const { profileData } = props;

    const [showSelectPerk, setShowSelectPerk] = useState(false);

    if (!profileData) {
        return;
    }

    return (
        <>
            {profileData?.indiegogoPerk ?
                <>
                    <Row className="row-margin">
                        <Col md="4">
                            <span className="lable-style">INDIEGOGO Campaign: </span>
                            {profileData?.indiegogoPerk?.perkName}
                        </Col>
                        <Col md="4">
                            <span className="lable-style">PERK Purchased: </span>
                            {profileData?.indiegogoPerk?.perkName}
                        </Col>
                        <Col md="4">
                            <span className="lable-style">Date Purchased: </span>
                            {moment(profileData?.indiegogoPerk?.datePurchased).format("MM-DD-YYYY hh:mm a")}
                        </Col>
                    </Row>

                    <Row className="row-margin">
                        <Col md="4">
                            {profileData?.indiegogoPerk?.membershipType}
                        </Col>
                        <Col md="4">
                            {profileData?.indiegogoPerk?.years} year(s)
                        </Col>
                        <Col md="4">
                            Renewal Date (Notification)
                        </Col>
                    </Row>
                    <Row className="row-margin">
                        <Col md="4">
                            <span className="lable-style">Free Games: </span>
                            {profileData?.indiegogoPerk?.freeGames}
                        </Col>
                    </Row>
                </>
                :
                <Row className="row-margin">
                    <Col md="12">
                        <span className="lable-style">No INDIEGOGO Campaign available.</span>
                    </Col>
                </Row>
            }


            <Button style={{ marginTop: '50px' }} className="nav-btn" onClick={() => setShowSelectPerk(true)}>
                Select INDIEGOGO PERK
            </Button>
            <AssignIndieGogoPerk
                open={showSelectPerk}
                ecommerceUserId={profileData?._id}
                onClose={() => {
                    setShowSelectPerk(false);
                }}
            />
        </>
    );
};

export default IndeigogoTab;
