import React from "react";
import { Row, Col } from "react-bootstrap";

const TokkenzTab = (props) => {
  const { profileData, giftCalculation } = props;

  if (!profileData) {
    return;
  }

  return (
    <>
      {giftCalculation && (
        <>
          <Row>
            <Col>
              <span className="lable-style">Total Cash Gift Received: </span>
              ${giftCalculation?.data?.totalCashGiftReceived} (USD)
            </Col>
            <Col>
              <span className="lable-style">Total Cash Gift Available: </span>
              ${giftCalculation?.data?.totalCashGiftAvailable} (USD)
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <span className="lable-style">Total Game Items: </span>
              {giftCalculation?.data?.totalGiftItems} Tokkenz
            </Col>
            <Col>
              <span className="lable-style">Total Gift Cards: </span>
              {giftCalculation?.data?.totalGiftCards} Tokkenz
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <span className="lable-style">Total VISA Cards: </span>
              {giftCalculation?.data?.totalPrepaidCards} Tokkenz
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TokkenzTab;
